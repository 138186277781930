<template>
  <div class="container-Pagamento">
    
        <!-- -->
      <section class="container-fluid">
        <section class="container">
          <div class="row">
            <div class="col-sm-12"><br>
            <h1 style="color:#400041;" class="bold d-block mx-auto">Conheça nossos planos</h1><br>
            <p>A um preço em que você dificilmente encontraria um bom livro de receitas, você pode ter um repertório de receitas que cresce e evolui indefinidamente + um mecanismo de inovador de busca + funcionalidades adicionais (Receitas a Testar e Lista de Compras) + a possibilidade de interagir com uma rede de cozinheiros tarimbados.</p>
            
           <!-- <img src="img/outdoor-fome.jpeg" alt="outdoor" class="img-fluid d-none d-sm-block" style="display:block;margin:0 auto;" />
            <img src="img/outdoor-fome-mobile.jpeg" alt="outdoor" class="img-fluid d-sm-none" style="display:block;margin:0 auto;" /> -->
            
            
            <p v-if="ativo == false && logado.campanha == undefined" class="text-center" style="color:red;">Sua conta expirou! Para continuar utilizando nossos serviços é nescessário estender sua assinatura.</p>
             <p v-if="logado.campanha != undefined && logado.campanha != null && logado.campanha == true" class="text-center" style="color:red;">Para utilizar nossos serviços é nescessário efetuar o pagamento da taxa de acesso.</p>
             <p v-if="erro" class="text-center">Houve uma falha de comunicação com o serviço de pagamento! por favor tente novamente em instantes... </p>
            <div v-if="showLoading" class="d-flex justify-content-center">
        <p class="">Estamos preparando o seu pagamento! Aguarde... </p>
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
            </div>
            </div>
        </section>

        <div class="row">

          <div class="col-sm-12  quadro">
            <br>
            <div class="espacamento">

              <section class="row">

              <!--  <div class="col-sm-3  " v-if="logado.campanha == undefined">
                  <div class="box-planos px-0">
                    <div class="box-top">
                      <h3 class=" text-center bold">DEGUSTAÇÃO</h3>
                    </div>
                    <div class="box-preco">
                      <h3 class="text-center bold">R$ 3,90</h3>
                    </div>
                    <div class="box-baixo">
                      <p class="text-center ">Renovação da Desgustação<br> <strong>por mais 60 dias</strong></p><br>
                      <button @click="comprar('3.90','Degustacao',1)" class="btn-comprar d-block mx-auto bold">{{textoBotao}}</button>
                    </div>
                  </div>
                  </div> -->

                <div class="col-sm-3 " v-if="logado.campanha == undefined">
                   <div class="box-planos px-0">
                   <div class="box-top">
                     <h3 class=" text-center bold">SEMESTRAL</h3>
                  </div>
                   <div class="box-preco">
                     <h3 class="text-center bold">R$ 9,90</h3>
                  </div>
                   <div class="box-baixo">
                     <p class="text-center "><span class="font-taxa">Taxa única</span> de acesso por<br> <strong>6 meses</strong></p><br>
                     <button @click="comprar('9.90','Semestral',2)" class="btn-comprar d-block mx-auto bold">{{textoBotao}}</button>
                  </div>
                  </div>

                  </div><!-- -->

                <div class="col-sm-3 " v-if="logado.campanha == undefined">
                   <div class="box-planos px-0">
                   <div class="box-top">
                     <h3 class=" text-center bold">ANUAL</h3>
                  </div>
                   <div class="box-preco">
                     <h3 class="text-center bold">R$ 19,90</h3>
                  </div>
                   <div class="box-baixo">
                     <p class="text-center "><span class="font-taxa">Taxa única</span> de acesso por<br> <strong>1 ano</strong></p><br>
                      <button @click="comprar('19.90','Anual',3)" class="btn-comprar d-block mx-auto bold">{{textoBotao}}</button>
                  </div>
                  </div>
                  </div><!-- -->

                   <div class="col-sm-4 offset-sm-4 " v-if="logado.campanha != undefined && logado.campanha != null && logado.campanha == true">
                   <div class="box-planos px-0">
                   <div class="box-top" style="background:#400041 !important;position:relative;">
                     <img src="/img/promocao.png" class="img-fluid" style="position:absolute;right:-70px;top:50px;"/>
                     <h3 class=" text-center text-white bold">ANUAL</h3>
                  </div>
                   <div class="box-preco">
                     <h3 class="text-center bold">R$ 5,40</h3>
                  </div>
                   <div class="box-baixo">
                     <p class="text-center "><span class="font-taxa">Taxa única</span> de acesso por<br> <strong>1 ano</strong></p><br>
                      <button @click="comprar('5.40','Anual',3)" class="btn-comprar d-block mx-auto bold">{{textoBotao}}</button>
                  </div>
                  </div>
                  </div><!-- -->

                <div class="col-sm-3 " v-if="logado.campanha == undefined">
                   <div class="box-planos px-0">
                   <div class="box-top" style="background:#400041 !important;position:relative;">
                     <img src="/img/promocao.png" class="img-fluid" style="position:absolute;right:-70px;top:50px;"/>
                     <h3 class="bold text-center" style="color:#FFF;">PERMANENTE</h3>
                  </div>
                   <div class="box-preco">
                     <h3 class="text-center bold">R$ 19,90</h3>
                  </div>
                   <div class="box-baixo">
                     <p class="text-center "><span class="font-taxa">Taxa única</span> de acesso por<br> <strong>período permanente</strong></p><br>
                     <button @click="comprar('19.90','Permanente',4)" class="btn-comprar d-block mx-auto bold">{{textoBotao}}</button>
                  </div>
                  </div>
                  </div><!-- -->

              </section>
           
            
                
            </div>
                 


               
          </div><!-- col -->

         

        </div>
      </section>

      

<br><br><br><br>


      <!-- -->
  </div>
</template>

<style>
.font-taxa{font-size:1.2em;font-weight: bold;}
.box-planos{-webkit-box-shadow: 2px 5px 13px 3px #545454; 
box-shadow: 2px 5px 13px 3px #545454;}
.btn-comprar{
    border-radius: 50px;
    display: inline-block;
    font-size: 18px;
    outline: 0;
    padding: 12px 30px;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid #440046;
    color: #440046;
    cursor: pointer;
    background-color:#FFF;}
    .btn-comprar:hover{background-color:#400041;color:#FFF;}
.box-baixo{padding: 40px 5px;}
.box-preco{background-color: #F6F6F6;padding:70px 5px 30px 5px;}
.box-top{background-color:#E0E0E0;color:#666666;padding: 20px 0px;}
.espacamento{padding:0 10%;}
.fundo-laranja{background:#F18800;padding-bottom:20px;}
.fundo-roxo{background:#400041;padding-bottom:20px;}
.fundo-roxo2{background:#764573;padding-bottom:20px;}
.quadro{min-height:300px;}

</style>

<script>
import servidor from '@/servidor'

export default {
  name: 'Pagamento',
  metaInfo: {
    title: 'Formas de pagamento - Tarimba na cozinha'
  },
  components: {},
  data(){
    return {
     showLoading:false,
     erro:false,
     textoBotao:'COMPRAR'
    }
  },
  methods:{
    abrirPagamento(codigo){
      let contexto = this
      window.PagSeguroLightbox({code: codigo},
      {
				success : function(transactionCode) { 
					console.log(transactionCode)
         contexto.$router.push('/transacoes')
				}, 
				abort : function() { 
				console.log('pagamento cancelado') 
				}
				
			});
    },
   comprar(preco,tipo,id){

     if(this.$store.state.usuario != null){

      if(this.$store.state.usuario.cadastro != true){
        this.$store.commit('showAlertaCadastro',true);
        this.$router.push('/perfil');
        return;
      }

     this.showLoading = true;
     this.erro = false;
     this.textoBotao = "AGUARDE...";
     let dados = new FormData();
     dados.append('tipo',tipo);
     dados.append('id',id);
     dados.append('preco',preco);
     dados.append('usuario',this.$store.state.usuario.id);
     dados.append('nome',this.$store.state.usuario.nome);
     dados.append('email',this.$store.state.usuario.email);
   
      
      fetch(servidor+'pagseguro/checkout/',{method:"POST", body:dados})
          .then((response) => response.json())
          .then((data) => {
            this.showLoading = false;
            this.textoBotao = "COMPRAR";
            console.log(data);
             window.location.href = data.url;
           //this.abrirPagamento(data.codigo[0]);

          }).catch(() => {
            this.textoBotao = "COMPRAR";
             this.showLoading = false;
              this.erro = true;
               
          })
      }else{
        this.$router.push('/cadastro');
      }
   }
  },
  computed:{
    ativo(){
      if(this.$store.state.usuario != null){
        let ativo =  this.$store.state.usuario.ativo;
        return ativo;
      }else{
        return true;
      }
      
      
    },
     logado(){
      if(this.$store.state.usuario != null){
       return  this.$store.state.usuario;
      }else{
        return {};
      }
      
      
    },
  },
  
  mounted(){
     let checkoutScript = document.createElement('script')
      checkoutScript.setAttribute('src', 'https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js')
      document.head.appendChild(checkoutScript)

  }
}
</script>